import bookreportHome from '../img/projects/bookreport/home.png';
import bookreportHR from '../img/projects/bookreport/hr.png';
import bookreportPurchasingMP4 from '../img/projects/bookreport/purchasing.mp4';
import bookreportPurchasingWebM from '../img/projects/bookreport/purchasing.webm';
import bookreportPurchasingGif from '../img/projects/bookreport/purchasing.gif';
import bookreportAccountingMP4 from '../img/projects/bookreport/accounting.mp4';
import bookreportAccountingWebM from '../img/projects/bookreport/accounting.webm';
import bookreportAccountingGif from '../img/projects/bookreport/accounting.gif';
import bookreportAccountCodeSearchMP4 from '../img/projects/bookreport/account-code-search.mp4';
import bookreportAccountCodeSearchWebM from '../img/projects/bookreport/account-code-search.webm';
import bookreportAccountCodeSearchGif from '../img/projects/bookreport/account-code-search.gif';
import bookreportBudgetsMP4 from '../img/projects/bookreport/budgets.mp4';
import bookreportBudgetsWebM from '../img/projects/bookreport/budgets.webm';
import bookreportBudgetsGif from '../img/projects/bookreport/budgets.gif';
import bookreportBankRecMP4 from '../img/projects/bookreport/bankrec.mp4';
import bookreportBankRecWebM from '../img/projects/bookreport/bankrec.webm';
import bookreportBankRecGif from '../img/projects/bookreport/bankrec.gif';
import bookreportBoardReportsMP4 from '../img/projects/bookreport/board-reports.mp4';
import bookreportBoardReportsWebM from '../img/projects/bookreport/board-reports.webm';
import bookreportBoardReportsGif from '../img/projects/bookreport/board-reports.gif';
import bookreportOrgAdminsMP4 from '../img/projects/bookreport/org-admin.mp4';
import bookreportOrgAdminsWebM from '../img/projects/bookreport/org-admin.webm';
import bookreportOrgAdminsGif from '../img/projects/bookreport/org-admin.gif';

import murrayWhiteboard from '../img/projects/pivotal/whiteboard.png';
import productWorkshop from '../img/projects/pivotal/product-workshop.JPG';
import speakers from '../img/projects/speakers/leanconf.png';
import foodForwardApp from '../img/projects/foodforward/app.png';
import farmersMarketRecoveryProgram from '../img/projects/foodforward/farmers.png';
import frsLogo from '../img/projects/frs/logo.jpg';
import farm from '../img/projects/slf/logo.jpg';
import slf1 from '../img/projects/slf/slf1.JPG';
import slf2 from '../img/projects/slf/slf2.JPG';
import somersault from '../img/projects/kglobal/somersault.png';
import goodLogo from '../img/projects/good/good-logo.jpg';

// hero
export const heroLineOne = "I'm an outcome-oriented"
export const heroLineTwo = "<em>product leader</em> and <em>coach</em>"
export const heroLineThree = "I build <em>products</em>, <em>people</em>,"
export const heroLineFour = "and <em>companies</em>"

export const aboutParagraphs = [
  "I've been building products, people, and companies for nearly 20 years, \
   so I understand that your product strategy is your business strategy. I get the \
   most out of your team by defining and prioritizing what we're building and \
   putting the right people in place to execute successfully. I've found that \
   people do their best work when they're not stressed out, so I focus on building systems \
   and teams that function smoothly under pressure.",

  "I've seen an IPO, built 6 product teams from the ground up, helped open up \
   the first German office for Pivotal, and been a VP of Product for the last 5 years.\
   I ask “Why are we doing this?” and “What does this mean in terms of what we are leaving behind?”",
]

// work experience
export const workExperienceTitle = "An overview of my work:"
export const workExperiences = [{
  year: '2021 - Now',
  skill: 'Product Consulting & Coaching',
  placeName: 'Independent',
  placeSlug: 'about',
}, {
  year: '2022',
  skill: 'Product Strategy',
  placeName: 'Decent',
  placeSlug: 'decent',
  role: 'as VP of Product',
}, {
  year: '2019 - 2021',
  skill: 'Product Education',
  placeName: 'Product Institue',
  external: true,
  placeSlug: 'https://productinstitute.com/p/about-us',
  role: 'as VP of Product',
}, {
  year: '2018 - 2019',
  skill: 'Product Process',
  placeName: 'Bookreport',
  placeSlug: 'bookreport',
  role: 'as Head of Product',
}, {
  year: '2014 - 2018',
  skill: 'Leadership & Product',
  placeName: 'Pivotal Labs',
  placeSlug: 'pivotal',
  role: 'as Associate Director',
}, {
  year: '2012 - 2014',
  skill: 'Product & Founding',
  placeName: 'Food Forward',
  placeSlug: 'foodforward',
  role: 'as Director',
}, {
  year: '2012 - 2013',
  skill: 'Product & Farming',
  placeName: 'Silverlake Farms',
  placeSlug: 'silverlakefarms',
}, {
  year: '2012',
  skill: 'Product',
  placeName: 'GOOD',
  placeSlug: 'good',
}, {
  year: '2011 - 2012',
  skill: 'Product',
  placeName: 'K-Global',
  placeSlug: 'kglobal',
}, {
  year: '2005 - 2009',
  skill: 'Product & Founding',
  placeName: 'FRS',
  placeSlug: 'frs',
  // }, {
  //   year: '2012 - 2014',
  //   skill: 'Speaking & Facilitation',
  //   placeName: 'Workshops & Conferences',
  //   placeSlug: 'speaking',
}]

// work experience detail pages
export const bookreport = {
  name: 'Bookreport',
  role: 'Head of Product',
  summaryIndefiniteArticle: 'a',
  summary: 'new kind of K-12 financial ERP',
  url: 'http://bookreport.io',
  accent: '#33ba8a',
  description: `
    As Head of Product, I managed 6 K-12 financial ERP products with different maturities.
    There was no product process in place, and work was not being actively prioritized.
    To align our team, I established a consistent
    product design and development process and defined our OKRs. We aimed to reduce
    the number of hours for school accountants to complete bank reconciliation by 80%,
    eventually reducing it by 99% to 20 minutes. The automated process renewed all
    contracts, doubled our revenue, and acquired 4 new schools. A culture of iteration,
    feedback, and productivity emerged. I learned that a process that emphasizes why a
    task is important can help team members understand how to deliver on it.
  `,
  slides: [{
    url: bookreportHome,
    title: 'K-12 Financial ERP',
    description: `
        BookReport is a financial & back office SaaS for schools — integrating budgeting,
  purchasing, approvals, accounting, HR, and compliance reporting into one system.
        Our goal is to empower districts to prioritize student spending on what matter most through
        financial efficiency and transparency.`,
    isVideo: false,
  }, {
    url: bookreportPurchasingMP4,
    mp4: bookreportPurchasingMP4,
    webm: bookreportPurchasingWebM,
    gif: bookreportPurchasingGif,
    youtube: 'oqLUJBZex2k',
    title: 'Purchasing',
    description: `The purchasing system lets teachers request what they need,
  which budget to use, and specify the org role that should approve the request.
                  Introducing this feature set saved our customers time and paper.`,
    isVideo: true,
    style: { width: '115.7%', marginLeft: '-3.75%', },
  }, {
    url: bookreportAccountingMP4,
    mp4: bookreportAccountingMP4,
    webm: bookreportAccountingWebM,
    gif: bookreportAccountingGif,
    youtube: 'dqupUJzTKDk',
    title: 'Accounting',
    description: `Bookreport's accounting system lets accountants manage the books and
                  prevents errors through data validation and self - healing.It integrates with purchasing
  (and everything else in Bookreport) which saves time and prevents errors.`,
    isVideo: true,
    style: { width: '106.67%', marginLeft: '-4.583%', },
  }, {
    url: bookreportBankRecMP4,
    mp4: bookreportBankRecMP4,
    webm: bookreportBankRecWebM,
    gif: bookreportBankRecGif,
    youtube: 'A2L-Uq5XZJg',
    title: 'Bank Rec',
    description: `
      We wrote a bank reconciliation application to reduce the number of hours to complete
      a bank rec from 24 hours to 20 mins, and set up the accounts to submit a completed bank
      rec by the 10th, instead of the end of the month.
    `,
    isVideo: true,
    style: { width: '106%', marginLeft: '-6%', },
  }, {
    url: bookreportHR,
    title: 'HR',
    description: `
      Bookreport handles the end to end onboarding process for a new hire,
  including creating & sending offer letters,
    onboarding new employees, generating I - 9s and W - 4s,
      and collecting all new hire information quickly and electronically.
    `,
    isVideo: false,
    style: { width: '107%', marginLeft: '-4%', },
  }, {
    url: bookreportAccountCodeSearchMP4,
    mp4: bookreportAccountCodeSearchMP4,
    webm: bookreportAccountCodeSearchWebM,
    gif: bookreportAccountCodeSearchGif,
    youtube: 'hEF2x1d6sqk',
    title: 'Account Code Smart Search',
    description: `This was a very tricky problem to solve because there are so many moving parts,
  but we're happy with how it came out.  It's easy to find
                  the account code you're looking for, the UI auto-populates missing values for you,
                  and you can't make an error.`,
    isVideo: true,
    style: { width: '138.89%', marginLeft: '-12.22%', marginTop: '-6%' },
  }, {
    url: bookreportBudgetsMP4,
    mp4: bookreportBudgetsMP4,
    webm: bookreportBudgetsWebM,
    gif: bookreportBudgetsGif,
    youtube: 'ZWR4eNaU2qg',
    title: 'Budgets',
    description: `We made it easy for schools to see how their budgets are doing in real-time,
                  and to compare their spending across time periods.`,
    isVideo: true,
    style: { width: '105.56%', marginLeft: '-4.167%', },
  }, {
    url: bookreportBoardReportsMP4,
    mp4: bookreportBoardReportsMP4,
    webm: bookreportBoardReportsWebM,
    gif: bookreportBoardReportsGif,
    youtube: 'Tfitx6SICCQ',
    title: 'Board Reports',
    description: `The reports are valuable but difficult to get right, from both the technical
                  and user experience perspectives.
                  We wrote extensive test coverage and added health-checks for many of these reports
                  so that they could be easily configured and still behave reliably throughout the fiscal year.`,
    isVideo: true,
    style: { width: '106.94%', marginLeft: '-6.944%', },
  }, {
    url: bookreportOrgAdminsMP4,
    mp4: bookreportOrgAdminsMP4,
    webm: bookreportOrgAdminsWebM,
    gif: bookreportOrgAdminsGif,
    youtube: 'n-Euj6Hl0iw',
    title: 'Organization Admin',
    description: `It became apparent that a nice organization management experience was essential.
                  There are a lot of customizable features so it had to be easy to
                  (1) understand the configuration options and (2) easily find
                  something that you needed to change for your organization.`,
    isVideo: true,
    style: { width: '106.94%', marginLeft: '-6.944%', },
  }],
  tags: [
  ],
}

// NOTE the decent content is currently copy/pasted from pivotal
export const decent = {
  name: 'Decent',
  role: 'VP of Product',
  summaryIndefiniteArticle: 'a',
  summary: 'Affordable Healthcare and Insurance  Startup',
  url: 'https://www.decent.com',
  accent: '#00b5a3',
  description: `
    As the VP of Product (PM, Design, and Creative), I managed 28 regulated SaaS products without
    a roadmap or team. I collaborated with the founders to prioritize the highest value problems we
    should invest in, created a roadmap, centralized knowledge, hired talent, and established clarity
    on why this work was the highest valuable thing we could be working on.
    <br />
    <br />
    Our team became productive, leaving everyone feeling less stressed out and confused. We hit our OKRs,
    accelerated our decision making, and tightened our feedback loops.
    Startups are hard, but our team helped kept things calm.
  `,
  slides: [],
  tags: [],
}

export const pivotal = {
  name: 'Pivotal',
  role: 'Associate Director of Product Management',
  summaryIndefiniteArticle: 'a',
  summary: 'Software Consultancy',
  url: 'https://pivotal.io',
  accent: '#00b5a3',
  description: `
    As an Associate Director of Product Management, I advised startups and Fortune 500 companies on product development while building and leading local product management practices in Los Angeles and Berlin, and opening up the first office in Germany. This work contributed directly to our successful IPO.
 `,
  slides: [{
    url: productWorkshop,
    title: 'Leadership & Operations',
    description: `
      I treated the team itself as a product &mdash; defining outcomes, prioritizing initiatives,
      running experiments, templatizing systems, socializing best practices &mdash;
      and we measured ourselves routinely to check we were on the right track. 
      <br />
      <br />
      I measured my success by my feedback. If my team, or our clients,
      wanted more of my time, then I knew I was providing value. I learned that
      hiring people you enjoy being around, who are eager to learn and receptive to feedback,
      is critical to success.
    `,
  }, {
    url: murrayWhiteboard,
    title: 'Product Manager',
    description: `
      Think, Make, Check
      <br />
      <br />
      I worked with a <a target="_blank" rel="noopener noreferrer" href="http://www.balancedteam.org/">balanced team</a> of Developers, Product Designers,
      stakeholders, and anyone else I needed to work with to ensure we prioritized and built the right thing.
    `,
  }],
  tags: [
  ],
}

export const speaking = {
  name: 'Speaking Engagements & Workshops',
  role: 'Facilitator',
  accent: '#172f42',
  summaryIndefiniteArticle: '',
  summary: '',
  description: `
    As a facilitator and educator, I have led 70+ workshops on product strategy & development topics,
    including helping <a target="_blank" rel="noopener noreferrer" href="http://theleanstartup.com/">Eric Ries</a> test out his material for his second book, The Leader's Guide,
    and developing the PM bootcamp courses for <a target="_blank" rel="noopener noreferrer" href="https://melissaperri.com/">Melissa Perri</a>, the author of <a target="_blank" rel="noopener noreferrer" href="https://melissaperri.com/book">The Build Trap</a>.
    <br />
    <br />
    I've had the privilege to speak about product development, collective decision making,
    and food waste policy. I founded a speaker series, and moderated panels. Here's a few examples:
  `,
  slides: [{
    url: speakers,
    title: 'Events',
    description: `
      – LeanConference: The Leader's Guide (sold out)<br />
      – <a target="_blank" rel="noopener noreferrer" href="https://www.womenwhocode.com/">
        Women Who Code: Berlin chapter</a><br />
      – <a target="_blank" rel="noopener noreferrer" href="http://www.balancedteam.org/">
        Balanced Team Berlin & California: moderator & event organizer</a><br />
      – <a target="_blank" rel="noopener noreferrer" href="http://web.archive.org/web/20151127041647/http://www.productmashupseries.com/past-mashups-blog/">
        Product Mashup: co-founder & moderator (monthly series, avg 50 attendees)</a><br />
      – Silicon Beach Fest: Developers & Product Managers working together<br />
      – <a target="_blank" rel="noopener noreferrer" href="https://www.sustain.ucla.edu/">
        Sustainability department at UCLA - Food Waste</a><br />
      – <a target="_blank" rel="noopener noreferrer" href="https://www.goodfoodla.org/">
        Los Angeles Food Policy Council</a><br />
      – <a target="_blank" rel="noopener noreferrer" href="http://www.smc.edu/Pages/Home.aspx">
        Sustainability department Santa Monica College - Food Waste</a><br />
    `,
  }],
  tags: [
  ],
}

export const foodForward = {
  name: 'Food Forward',
  role: 'Director & Product Manager',
  summaryIndefiniteArticle: 'a',
  url: 'https://foodforward.org/',
  summary: 'Food Waste Non-profit',
  accent: '#f46219',
  description: `
    I led a team that improved the hunger and food waste condition in Los Angeles
    by organizing volunteers to collect unsold produce from farmers markets
    and redistribute it to local hunger relief agencies, and developing software to improve our operations. 
  `,
  slides: [{
    url: foodForwardApp,
    title: 'Volunteer & Event Management App',
    description: `
      Volunteer Organization + Event Management + Analytics = More People Fed
      <br /><br />
      As the program grew we became overwhelmed by our workload.
      We applied for a grant to develop a software solution, and I led the
      product effort to automate our operation. I worked with a designer and a team
      of developers, and within 6 months we released an event management platform
      that helped the team recoup 30% of their time.
    `,
  }, {
    url: farmersMarketRecoveryProgram,
    title: 'Farmers Market Recovery Program',
    description: `
      We found an opportunity to feed hungry Californians by collecting the
      unsold produce at farmers markets and redistributing it to local hunger relief agencies.
      I applied my user research & product development skills to design and iterate on the program.
      We grew to 250+ regular volunteers working 11 farmers markets per week.
      We rescued 100+ tons of produce per year from from the compost pile to feed 200k+ people from 220+ farmers.
      The program's success was covered by The Huffington Post, KCET, and Take Part.
    `,
  }],
  tags: [
  ],
}

export const frs = {
  name: 'FRS',
  url: 'https://www.frs.com/',
  role: 'Sales & Marketing Manager',
  accent: '#f84767',
  summaryIndefiniteArticle: 'a',
  summary: 'Health and Wellness Nutrition Company',
  description: `
    Part of an 8-person launch team, I supported company growth to 100 employees.
    I ran three initial sales territories, marketing programs, and budget forecasting.
    I was promoted 3 times in 4 years, grew key accounts from 0 to 2000,
    increased sales 186%, and was awarded top sales manager three years in a row.
    I consider this my mini MBA.
  `,
  slides: [{
    url: frsLogo,
    title: 'product & sales',
    description: `
      I learned to listen to customer needs, apply user research techniques to increase customer value,
      and validate our sales and e-commerce strategies.
      I helped create and manage our community site, social accounts, blog,
      and did A/B testing for e-Commerce. I help write key messages, brand communications,
      marketing collateral, design point of sale materials, and online content.
    `,
  }],
  tags: [
  ],
}

export const silverLakeFarms = {
  name: 'Silver Lake Farms',
  role: 'Product & Garden Designer',
  accent: '#a8bb86',
  url: 'https://www.instagram.com/silverlakefarms',
  summaryIndefiniteArticle: 'a',
  summary: 'Flower Farm and CSA in Los Angeles',
  description: `
    I am a passionate gardener and I volunteer
    at school gardens and various community projects,
    including getting certified as a Master Gardener through the University of California program. 
  `,
  slides: [{
    url: farm,
    title: 'product & operations',
    description: `
      At SLF I  designed and maintained client gardens, supported our CSA distribution,
      and grew and arranged flowers for farmers markets. I did some product & operational work.
      I applied my user research skills and produced the website, wrote copy, and improved back office operations. 
    `,
  }, {
    url: slf1,
    title: 'Client Garden: Before',
    description: `
    `,
  }, {
    url: slf2,
    title: 'Client Garden: After',
    description: `
    `,
  }],
  tags: [
  ],
}

export const good = {
  name: 'GOOD.is',
  url: 'https://www.good.is/',
  role: 'Partnership & Strategy Manager',
  summaryIndefiniteArticle: 'a',
  summary: 'civic-minded media company and creative agency',
  accent: '#009DFF',
  description: `
    At GOOD I worked on I worked on brand strategy and interactive platforms for General Electric.
    `,
  slides: [{
    url: goodLogo,
    title: "GOOD.is",
    description: `
      I provided expertise on campaign strategy and aligned execution with client goals & metrics.
      I managed the roadmap, wrote and prioritize user stories, and user flows, and served as the client contact.
      I led a cross-functional team of designers and contract developers to develop and deliver the platform quickly.
    `,
    style: { margin: '-2% 0 0' },
  }],
  tags: [
  ],
}

export const kglobal = {
  name: 'k-global',
  url: 'https://www.kglobal.com/',
  role: 'Strategist - Interactive & Product',
  summaryIndefiniteArticle: 'a',
  summary: 'full-service brand strategy & PR agency',
  accent: '#ed6538',
  description: `
    I designed and produced interactive sites, webisodes, and  brand communications.
    I was accountable for project deliverables, design, and developer teams.
    I defined and managed roadmaps, user stories, site maps, and user flows.
    I wrote site & product copy, brand books, key messages, and blog posts. 
    `,
  slides: [{
    url: somersault,
    title: "Somersault Snacks ($60k)",
    description: `
      Launched "Power of Seeds" platform; including web, blog, social, and spokesperson.
      As a result, Somersaults gained significantly increased exposure in the media (including Oprah magazine)
      and beyond and is now nationally distributed at well-known retailers, like Whole Foods.
    `,
    // style: { margin: '0' },
  }, {
    forceYoutube: true,
    youtube: 'QUWLtUbqLzA',
    title: 'H. K. Anderson Pretzels ($1m)',
    description: `
      Proposed web campaign and increased H.K.
      budget by $500k within 2 months of start.
      Funneled microsite users to like H. K. on Facebook,
      grew Facebook from 0 - 20k fans, which at the time was a trendy strategy.
    `,
    isVideo: true,
    style: { width: '100%', marginLeft: '0%', },
  }],
  tags: [
  ],
}
