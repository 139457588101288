import React, { Component } from 'react';
import { Row, Col } from 'antd';
import './Summary.css';

import {
  workExperienceTitle,
  workExperiences,
} from './lib/projects';

export default class Summary extends Component {
  render() {
    return (
      <>
        <section className="summary">
          <section className="overview">
            {/* <div id="my-work" className="wire" /> */}
            <h1>{workExperienceTitle}</h1>
            {workExperiences.map((e, i) => (
              <Row key={i}>
                <Col span={5} className="col-year">
                  <div className="year">
                    {e.year}
                  </div>
                </Col>
                <Col span={19} className="col-desc">
                  <div className="desc">
                    {e.skill} at&nbsp;
                    {e.external &&
                      <a target="_blank" rel="noopener noreferrer" href={`${e.placeSlug}`}>{e.placeName}</a>
                    }
                    {!e.external &&
                      <a href={`/${e.placeSlug}`}>{e.placeName}</a>
                    }
                    &nbsp;{e.role}
                  </div>
                </Col>
              </Row>
            ))}
          </section>
        </section>
        <section className="how-i-work">
          <section className="overview">
            <Row>
              <Col span={5} className="col-year">
                <div className="year">
                  info
                </div>
              </Col>
              <Col span={19} className="col-desc">
                <div className="desc">
                  More about me and <span><a href="/about">how I work</a> &rarr;</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={5} className="col-year">
                <div className="year">
                </div>
              </Col>
              <Col span={19} className="col-desc">
                <div className="desc">
                  Speaking & Facilitation at <span><a href="/speaking">Workshops & Conferences</a> &rarr;</span>
                </div>
              </Col>
            </Row>
          </section>
        </section>
      </>
    );
  }
}
