import React, { Component } from 'react';
import './About.css';
import { aboutParagraphs } from './lib/projects';

export default class About extends Component {
  render() {
    return (
      <section className="about">
        <h1>How I work</h1>
        {aboutParagraphs.map((p, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: p }} />
        ))}
      </section>
    );
  }
}
