import React, { Component } from 'react';
import Hero from './Hero';
import Header from './Header';
import Summary from './Summary';
import ContactMe from './ContactMe';
import Project from './Project';
import About from './About';
import './App.css';
import './Responsive.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import {
  pivotal,
  bookreport,
  decent,
  foodForward,
  good,
  silverLakeFarms,
  kglobal,
  frs,
  speaking,
} from './lib/projects';

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<AboutMe />} />
          <Route path='/bookreport' element={<Bookreport />} />
          <Route path='/decent' element={<Decent />} />
          <Route path='/pivotal' element={<Pivotal />} />
          <Route path='/speaking' element={<Speaking />} />
          <Route path='/foodforward' element={<FoodForward />} />
          <Route path='/frs' element={<FRS />} />
          <Route path='/silverlakefarms' element={<SilverLakeFarms />} />
          <Route path='/good' element={<Good />} />
          <Route path='/kglobal' element={<KGlobal />} />
        </Routes>
      </Router>
    );
  }
}

function Home() {
  return (
    <div className="app">
      <Hero />
      <Summary />
      <ContactMe />
    </div>
  )
}

function AboutMe() {
  return (
    <div className="app">
      <Header />
      <About />
      <ContactMe />
    </div>
  )
}

function Bookreport() {
  return (
    <div className="app">
      <Header />
      <Project key={bookreport.name} project={bookreport} />
      <ContactMe />
    </div>
  )
}

function Decent() {
  return (
    <div className="app">
      <Header />
      <Project key={decent.name} project={decent} />
      <ContactMe />
    </div>
  )
}

function Pivotal() {
  return (
    <div className="app">
      <Header />
      <Project key={pivotal.name} project={pivotal} />
      <ContactMe />
    </div>
  )
}

function Speaking() {
  return (
    <div className="app">
      <Header />
      <Project key={speaking.name} project={speaking} />
      <ContactMe />
    </div>
  )
}

function FoodForward() {
  return (
    <div className="app">
      <Header />
      <Project key={foodForward.name} project={foodForward} />
      <ContactMe />
    </div>
  )
}

function FRS() {
  return (
    <div className="app">
      <Header />
      <Project key={frs.name} project={frs} />
      <ContactMe />
    </div>
  )
}

function SilverLakeFarms() {
  return (
    <div className="app">
      <Header />
      <Project key={silverLakeFarms.name} project={silverLakeFarms} />
      <ContactMe />
    </div>
  )
}

function Good() {
  return (
    <div className="app">
      <Header />
      <Project key={good.name} project={good} />
      <ContactMe />
    </div>
  )
}

function KGlobal() {
  return (
    <div className="app">
      <Header />
      <Project key={kglobal.name} project={kglobal} />
      <ContactMe />
    </div>
  )
}

export default App;
