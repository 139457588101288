export function smoothScroll(to, duration = 300) {
  const doc = document.documentElement;
  const from = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  let start = null;
  const easeInFn = t => t * t;

  const step = (timestamp) => {
    start = start || timestamp;
    const progress = timestamp - start;
    const time = Math.min(1, ((timestamp - start) / duration));

    window.scrollTo(from, from + (easeInFn(time) * to));
    if (progress < duration) {
      window.requestAnimationFrame(step);
    }
  };

  window.requestAnimationFrame(step);
}
