import React, { Component } from 'react';
import './Header.css';

export default class Header extends Component {
  render() {
    return (
      <section className="header">
        <a href="/">&lt; Back</a>
      </section>
    );
  }
}
