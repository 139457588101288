import React, { Component } from 'react';
import { smoothScroll } from './lib/scroll';
import { Icon, Tooltip } from 'antd';
import desk from './img/desk.png';
import './Hero.css';

import {
  heroLineOne,
  heroLineTwo,
  heroLineThree,
  heroLineFour,
} from './lib/projects';

export default class Hero extends Component {
  handleSeeMyWorkClick = () => {
    const rect = document.getElementById('my-work').getBoundingClientRect();
    smoothScroll(rect.top + 12, 1000);
  }

  handleContactMeClick = () => {
    const rect = document.getElementById('contact-me').getBoundingClientRect();
    smoothScroll(rect.top, 1000);
  }

  render() {
    return (
      <section className="hero">
        <div className="title">
          <div className="text">
            <h2>
              <span>&gt; Hi, I'm Mary.</span>
              <Tooltip title="Contact Me">
                <a className="contact-me" onClick={this.handleContactMeClick}>
                  <Icon type="message" />
                </a>
              </Tooltip>
              <Tooltip title="View my LinkedIn profile">
                <a className="social" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/marymbaldwin/">
                  <Icon type="linkedin" />
                </a>
              </Tooltip>
            </h2>
            <h3>
              <span dangerouslySetInnerHTML={{ __html: heroLineOne }}></span>
              <br /><span dangerouslySetInnerHTML={{ __html: heroLineTwo }}></span>
              <br />
              <br />
              <span dangerouslySetInnerHTML={{ __html: heroLineThree }}></span>
              <br /><span dangerouslySetInnerHTML={{ __html: heroLineFour }}></span>
            </h3>
            {/* <h1>
              <span dangerouslySetInnerHTML={{ __html: heroLineThree }}></span>
              <br /><span dangerouslySetInnerHTML={{ __html: heroLineFour }}></span>
            </h1> */}
          </div>
          <div className="image-wrap">
            <img className="image" src={desk} alt="" width="420px" />
          </div>
          {/* <h1>
            <span dangerouslySetInnerHTML={{ __html: heroLineThree }}></span>
            <br /><span dangerouslySetInnerHTML={{ __html: heroLineFour }}></span>
          </h1> */}
        </div>
      </section>
    );
  }
}
