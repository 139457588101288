import React, { Component } from 'react';
import { Row } from 'antd';
import ImageGallery from 'react-image-gallery';
import { BrowserView, MobileView } from "react-device-detect";
import './Project.css';

export default class Project extends Component {
  state = {
    stopPlayingAllGIFs: false,
  }

  tagToClassName(tag) {
    return tag.replace(/\./g, '')
      .replace(/\+/g, '')
      .replace(/3/g, 'three')
      .replace(/ /g, '-');
  }

  renderImage = (item) => {
    return <ImageSlide item={item} />;
  }

  renderVideo = (item) => {
    return (
      <VideoSlide item={item} stopPlaying={this.state.stopPlayingAllGIFs} />
    );
  }

  handleOnSlide = (currentIndex) => {
    this.setState({ stopPlayingAllGIFs: true });
  }

  render() {
    const {
      name,
      role,
      url,
      summaryIndefiniteArticle,
      summary,
      slides,
      tags,
      description,
      accent,
    } = this.props.project;

    const images = slides.map(slide => ({
      title: slide.title,
      description: slide.description,
      original: slide.url,
      thumbnail: slide.url,
      thumbnailClass: 'gallery-thumbnail',
      mp4: slide.mp4,
      webm: slide.webm,
      gif: slide.gif,
      youtube: slide.youtube,
      forceYoutube: slide.forceYoutube,
      style: slide.style,
      accent,
      renderItem: slide.isVideo
        ? this.renderVideo.bind(this)
        : this.renderImage.bind(this)
    }));

    return (
      <div className="project-container">
        <Row className="project">
          <Row>
            <h2>{name}</h2>
            {summary.length > 0 && [
              <span className="bridge">is {summaryIndefiniteArticle}&nbsp;</span>,
              <span className="subtitle">{summary}</span>
            ]}
          </Row>
          <Row className="link">
            <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: accent }}>
              {url}
            </a>
          </Row>
          <Row className="description">
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </Row>
          <Row className="detail">
            <section className="tags">
              <Row>
                <span className="role">{role}</span>
              </Row>
              {tags.map((tag, i) =>
                <span key={i} className={this.tagToClassName(tag)}>{tag}</span>
              )}
            </section>
            <section className="slides">
              <div className="frame">
                <ImageGallery
                  items={images}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  onSlide={this.handleOnSlide}
                  lazyLoad
                />
              </div>
            </section>
          </Row>
        </Row>
      </div>
    );
  }
}

class ImageSlide extends Component {
  render() {
    const { item } = this.props;

    return (
      <div key={item.original} className="image-gallery-image">
        <div className="picture-frame">
          <img
            style={item.style}
            src={item.original}
            alt={item.originalAlt}
            title={item.originalTitle}
          />
        </div>
        <div className="image-gallery-description">
          <div className="content">
            <h6 style={{ borderBottom: `1px solid ${item.accent}` }}>{item.title}</h6>
            <p dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        </div>
      </div>
    );
  }
}

class VideoSlide extends Component {
  constructor(props) {
    super(props);
    this.video = React.createRef();
  }

  state = {
    playing: false,
  }

  playVideo() {
    if (!this.video) {
      return false;
    }

    this.video.play();
    this.setState({ playing: true });
  }

  pauseVideo() {
    if (!this.video) {
      return false;
    }

    if (typeof this.video.pause === 'function') {
      this.video.pause();
      this.setState({ playing: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.stopPlaying) {
      this.pauseVideo();
    }
  }

  handleClick = (item) => {
    if (this.state.playing) {
      this.pauseVideo();
    } else {
      this.playVideo();
    }
  }

  renderMobileVideoPlayer(item) {
    const eightyPercent = 0.8 * window.innerWidth;
    const aspectRatio = 700.0 / 400.0;
    const videoWidth = eightyPercent;
    const videoHeight = videoWidth / aspectRatio;

    return (
      <div className="video-wrapper">
        <iframe
          title={item.title}
          src={`https://www.youtube.com/embed/${item.youtube}`}
          width={`${videoWidth}px`}
          height={`${videoHeight}px`}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
          webkitallowfullscreen
          mozallowfullscreen
        </iframe>
      </div>
    );
  }

  renderWebVideoPlayer(item) {
    if (!!window.chrome && !item.forceYoutube) {
      return (
        <div>
          <div className="hover-play">
            <div className="play-button" />
            <div className="text">Play GIF</div>
          </div>
          <div className="video-wrapper">
            <video
              ref={(c) => this.video = c}
              loop
              muted
              playsInline
              src={item.original}
              style={item.style}
            >
              <source src={item.webm} type="video/webm" />
              <source src={item.mp4} type="video/mp4" />
              <img alt="" src={item.gif} />
            </video>
          </div>
        </div>
      );
    }

    return (
      <iframe
        title={item.title}
        src={`https://www.youtube.com/embed/${item.youtube}`}
        width="700px"
        height="400px"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
        webkitallowfullscreen
        mozallowfullscreen
      </iframe>
    )
  }

  render() {
    const { item } = this.props;
    const playing = this.state.playing ? 'playing' : '';

    return (
      <div
        key={item.original}
        className={`image-gallery-image video-slide ${playing}`}
        onClick={this.handleClick.bind(this, item)}
      >
        <BrowserView>
          {this.renderWebVideoPlayer(item)}
        </BrowserView>
        <MobileView>
          {this.renderMobileVideoPlayer(item)}
        </MobileView>
        <div className="image-gallery-description">
          <div className="content">
            <h6 style={{ borderBottom: `1px solid ${item.accent}` }}>{item.title}</h6>
            <p dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        </div>
      </div>
    );
  }
}
